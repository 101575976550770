import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class PopupService {

    private readonly popupState = new BehaviorSubject<boolean>(false);
    private readonly inDate = new BehaviorSubject<boolean>(false);

  /** AuthState as an Observable */
    readonly popupState$ = this.popupState.asObservable();
    readonly inDate$ = this.inDate.asObservable();

  /** Observe the isLoggedIn slice of the auth state */
  //readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor(){
  }
    
  public SetPopupForPage(page: string, offerStartDate: string, offerEndDate: string, seconds: number = 5) {
        
    const now = new Date();
    if(new Date().getTime() > new Date(offerStartDate).getTime() && new Date().getTime() < new Date(offerEndDate).getTime()) {
        this.inDate.next(true);
    
        const current = this.GetStorageItem(page);

        if(current === null ) {
            this.SetStorageItem(page, PopupState.TimerSet);
            this.setTimer(page, seconds);
        }
      } else
      {
        this.inDate.next(false);
      }
  } 

  public ClosePopupForPage(page: string) {
    this.SetStorageItem(page, PopupState.PopupClosed);
    this.popupState.next(false);
  }

  private setTimer(page: string, seconds: number) {
    setTimeout(() => {
        
        this.SetStorageItem(page, PopupState.TimerFinished);
      },
      seconds * 1000
    );
  }

  private SetStorageItem(item: string, state: PopupState) {
    localStorage.setItem(item, JSON.stringify(state));
    if(state === PopupState.TimerFinished ) {
        this.popupState.next(true);
    }
  }

  private GetStorageItem(item: string) : any {
    return localStorage.getItem(item);
  }
}

export enum PopupState {
    TimerSet,
    TimerFinished,
    PopupClosed
}